<template>
  <v-row class="mt-1">
    <v-col cols="12" md="6">
      <v-card class="p-2">
        <p class="ms-0 my-0 f-sanspro fw-600 fs-10pt" v-cHex="hexShades(cor.active.B,0.5)">
          <i class="btn-icon-left" v-i:duo#upload#18></i>
          Importação das entregas - planilha (relatório) ULife
        </p>
        <hr class="mt-2 mb-2">
        <div class="text-right">
          <v-btn class="me-3" color="primary" outlined x-small @click="importar">
            <i class="btn-icon-left" v-i:duo#target#18></i>
            <span>importar</span>
          </v-btn>
          <v-btn color="primary" outlined x-small @click="$refs.carregaplanilharef.show()">
            <i class="btn-icon-left" v-i:duo#upload#18></i>
            <span>selecionar planilha</span>
          </v-btn>
        </div>
        <v-card class="p-2">
          <p class="ms-0 my-0 f-sanspro fw-600 fs-10pt" v-cHex="hexShades(cor.active.B,0.5)">
            <i class="btn-icon-left" v-i:duo#note#18></i>
            Dados recuperados da planilha (log)
          </p>
          <hr class="mt-2 mb-2">
          <div class="" v-cHex="hexShades(cor.active.B,0.5)">
            <p class="ms-0 my-0 f-sanspro fw-600 fs-10pt" v-for="(log,index) in logMsgs" :key="index">
              <i class="absolute" v-i:duo#circle#10></i>
              <span class="ms-4 fs-9pt">{{log.text}}</span>
              <span class="ms-1 fs-9pt f-roboto fw-700">{{log.value}}</span>
            </p>
          </div>
        </v-card>
      </v-card>
    </v-col>
    <carregaplanilha ref="carregaplanilharef" @atualiza="atualizaPlanilha"/>
  </v-row>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import carregaplanilha from './carrega-planilha.vue';
import moment from "moment"

export default {
  name: "importacao",
  components: { carregaplanilha },
  props: [],
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      planilha: [],
      profs: {},
      profsByCPF: {},
      ucs: {},
      ucsByName: {},
      metasIndicadas: {},
      metasIndicadasByCode: {},
      logMsgs: [],
    }
  },
  watch: {},
  mounted: function() {
    var self = this;

    rdb.ref('/usuarios/pessoas').on('value', function(snapshot) {
      self.profs = snapshot.val();
      console.log("self.profs ["+Object.keys(self.profs).length+"]", self.profs);
      self.profsByCPF = {};
      for(var key in self.profs) {
        self.profsByCPF[self.profs[key].cpf] = self.profs[key];
      }
      console.log("self.profsByCPF ["+Object.keys(self.profsByCPF).length+"]", self.profsByCPF);

      rdb.ref('/curriculo/avaliacao/2021S2/metasIndicadasFinal').on('value', function(snapshot) {
        self.metasIndicadas = snapshot.val();
        console.log("self.metasIndicadas ["+Object.keys(self.metasIndicadas).length+"]", self.metasIndicadas);
        self.metasIndicadasByCode = {};
        for(var key in self.metasIndicadas) {
          self.metasIndicadasByCode[self.metasIndicadas[key].metaCodigo] = self.metasIndicadas[key];
        }
        console.log("self.metasIndicadasByCode ["+Object.keys(self.metasIndicadasByCode).length+"]", self.metasIndicadasByCode);
        self.loadUcs();
      });
    });
  },
  methods:{

    loadUcs() {
      var self = this;
      rdb.ref('/curriculo/ucs').on('value', function(snapshot) {
        self.ucs = snapshot.val();
        self.ucsByName = {};
        for(var key in self.ucs) {
          var nome = self.ucs[key].nome.trim().toUpperCase();
          var vet = nome.split("(");
          if(vet.length>1) {
            nome = vet[0].trim();
          }
          nome = nome.trim().toUpperCase();
          if(nome.charAt(nome.length-1) == ".") { nome = nome.substr(0,nome.length-1); };
          if(nome.charAt(nome.length-1) == ";") { nome = nome.substr(0,nome.length-1); };
          if(nome.charAt(nome.length-1) == ",") { nome = nome.substr(0,nome.length-1); };
          nome = nome.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
          self.ucsByName[nome] = self.ucs[key];
        }
        console.log("self.ucsByName ["+Object.keys(self.ucsByName).length+"]", self.ucsByName);
        console.log("self.ucs ["+Object.keys(self.ucs).length+"]", self.ucs);
        self.build();
      });
    },

    getUCByName(nome) {
      var bestValue = 0;
      var bestNome = "";
      for(var key in this.ucsByName) {
        var value = stringSimilarity.compareTwoStrings(nome,key);
        if(value > bestValue) {
          bestValue = value;
          bestNome = key;
        }
      }
      if(bestValue < 0.99) {
        console.log("ATENÇÃO: "+nome+ "encontrado:",bestNome);
      }
      return this.ucsByName[bestNome];
    },

    getMetaByText(texto,ucID) {
      var bestValue = 0;
      var bestNome = "";
      var bestKey = "";
      //console.log("------------------------------------");
      //console.log("Procurando...",texto);
      for(var key in this.metasIndicadas) {
        if(this.metasIndicadas[key].ucID == ucID) {
          var metaTexto = this.metasIndicadas[key].texto.replaceAll("2021.1","").trim();
          metaTexto = metaTexto.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
          //console.log("verificando: meta UC:"+ucID,metaTexto);
          var value = stringSimilarity.compareTwoStrings(texto,metaTexto);
          if(value > bestValue) {
            bestValue = value;
            bestNome = metaTexto;
            bestKey = key;
          }
        }
      }
      if(bestValue < 0.99) {
        if(bestValue < 0.8) {
          console.log("ATENÇÃO: Meta DESCARTADA: "+texto+ " encontrada: ",bestNome);
          return undefined;
        } else {
          console.log("ATENÇÃO: Meta: "+texto+ " encontrada: ",bestNome);
        }
      } else {
        //console.log("ATENÇÃO: ENCONTREI Meta: "+texto+ " encontrada: ",bestNome);
      }
      return this.metasIndicadas[bestKey];
    },

    build() {
      var self = this;
      console.log("build");
      //this.planilha = JSON.parse(localStorage.getItem('planilha'));
      console.log("this.planilha",this.planilha);
      this.logMsgs = [];
      this.logMsgs.push({ text: "linhas encontradas (entregas):", value: this.planilha.length });
      var profsEncontrados = {};
      var ucsEncontradas = {};
      var metasEncontradas = {};
      var qtdeDeletados = 0;

      for(var idx in this.planilha) {
        var linha = this.planilha[idx];

        if(linha["Status da Questão"] != "Deletada" && linha["Descritor"] != undefined) {

          var prof = self.profsByCPF[""+parseInt(linha["CPF Autor"].trim())];
          if(prof != undefined) {
            profsEncontrados[prof.id] = prof;
          } else {
            this.logMsgs.push({ text: "profs NÃO encontrado:", value: linha["Nome do Autor"] });
          }

          var ucNome = linha["Nome do Banco"].trim().replaceAll("A2_2021/2 -","").trim();
          ucNome = ucNome.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
          var uc = this.getUCByName(ucNome);
          if(ucNome.indexOf("- ENGENHARIA") != -1) {
            console.log("- Engenharia");
            uc = self.ucs["-MeX0ONKz9LmTMLEkRaJ"];
          }
          if(ucNome.indexOf("- ARQUITETURA") != -1) {
            console.log("- Engenharia");
            uc = self.ucs["-MeX0ONKz9LmTMLEkRaI"];
          }
          //console.log(ucNome);
          if(uc != undefined) {
            ucsEncontradas[uc.id] = uc;
          } else {
            this.logMsgs.push({ text: "UC NÃO encontrada:", value: ucNome });
          }

          var metaCodigo = linha["ID Descritor"].trim();
          var meta = self.metasIndicadasByCode[metaCodigo];
          if(meta != undefined) {
            metasEncontradas[meta.id] = meta;
          } else {
            var texto = linha["Descritor"].trim();
            texto = texto.replaceAll("2021.1","").trim();
            texto = texto.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
            meta = this.getMetaByText(texto,uc.id);
            if(meta != undefined) {
              metasEncontradas[meta.id] = meta;
              this.planilha[idx]["ID Descritor"] = meta.metaCodigo;
            } else {
              this.logMsgs.push({ text: "META NÃO encontrada:", value: metaCodigo+" "+texto.substr(0,45)+"..." });
            }
          }
        } else {
          qtdeDeletados++;
        }

      }

      this.logMsgs.push({ text: "Status Deletado:", value: qtdeDeletados });
      this.logMsgs.push({ text: "profs encontrados:", value: Object.keys(profsEncontrados).length });
      this.logMsgs.push({ text: "UCs encontradas:", value: Object.keys(ucsEncontradas).length });
      this.logMsgs.push({ text: "Metas encontradas:", value: Object.keys(metasEncontradas).length });
    },

    atualizaPlanilha(planilha) {
      var self = this;
      console.log("atualizaPlanilha");
      //this.planilha = JSON.parse(localStorage.getItem('planilha'));
      this.planilha = planilha
      console.log("this.planilha",this.planilha);
      this.build();
    },

    getElemFromLine(linha) {
      var elem = {
        bancoID: linha["ID do Banco"],
        questaoID: linha["ID da Questão"],
        autorNome: linha["Nome do Autor"],
        autorCPF: linha["CPF Autor"],
        enunciado: linha["Enunciado da Questão"],
        dificuldade: linha["Nível de Dificuldade"],
        revisaoStatus: linha["Status da Revisão"],
        questaoTipo: linha["Tipo de Questão"],
        metaCodigo: linha["ID Descritor"],
        descritor: linha["Descritor"],
        bancoNome: linha["Nome do Banco"],
      };
      var vet = linha["Data da Criação"].split("/");
      var aux = vet[0];
      vet[0] = vet[1];
      vet[1] = aux;
      var createdAt = vet.join("/");
      elem.createdAt = moment(createdAt).format();

      var meta = this.metasIndicadasByCode[elem.metaCodigo];
      if(meta == undefined) {
        console.log("metaCodigo não encontrado",elem.metaCodigo);
        var nomeuc = elem.bancoNome.replaceAll("A2_2021/2 -","").trim();
        nomeuc = nomeuc.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
        var uc = this.ucsByName[nomeuc];
        if(uc != undefined) {
          elem.ucID = uc.id;
        } else {
          console.log("UC não encontrada",nomeuc);
        }
      } else {
        elem.metaID = meta.id;
        elem.ucID = meta.ucID;
        //AREAS
        var uc = this.ucs[elem.ucID];
        if(uc == undefined) {
          console.log(elem);
        } else {
          for(var idx in uc.areas) {
            elem['area'+uc.areas[idx].sigla] = true;
          }
        }
      }
      var prof = this.profsByCPF[""+parseInt(elem.autorCPF)];
      if(prof == undefined) {
        console.log("professor não encontrado",elem.autorCPF);
      } else {
        elem.autorID = prof.id;
      }
      return elem;
    },

    importar() {
      var self = this;
      console.log("importar");
      var entregas = [];
      var entregasSemMetas = [];

      for(var idx in this.planilha) {
        var linha = this.planilha[idx];
        if(linha["Status da Questão"] != "Deletada" && linha["Descritor"] != undefined) {
          var elem = this.getElemFromLine(linha);
          if(elem.metaID == undefined) {
            entregasSemMetas.push(elem);
          } else {
            entregas.push(elem);
          }
        }
      }

      console.log("entregas",entregas);
      console.log("entregasSemMetas",entregasSemMetas);

      var qtdeRevisao = 0;
      var qtdeCorrecao = 0;
      var qtdeLiberado = 0;
      var qtdeEntregas = 0;

      var updates = {};
      var i = 1;

      for(var idx in entregas) {
        var elem = entregas[idx];
        qtdeEntregas++;
        if(elem.revisaoStatus == "Revisão") {
          qtdeRevisao++;
        }
        if(elem.revisaoStatus == "Para correção") {
          qtdeCorrecao++;
        }
        if(elem.revisaoStatus == "Liberadas para aplicação") {
          qtdeLiberado++;
        }

        var elemLimpo = clone(elem);
        delete elemLimpo.autorNome;
        delete elemLimpo.bancoNome;
        delete elemLimpo.descritor;
        delete elemLimpo.dificuldade;
        delete elemLimpo.enunciado;
        delete elemLimpo.questaoTipo;
        updates["/curriculo/avaliacao/2021S2/entregasA2/"+elem.questaoID] = elem;
        updates["/curriculo/avaliacao/2021S2/entregasA2Limpo/"+elem.questaoID] = elemLimpo;
        if(i%1000 == 0) {
          console.log("updates ("+i+") ["+Object.keys(updates).length+"]",updates);
          rdb.ref().update(updates);
          updates = {};
        }
        i++;
      }


      console.log("updates ("+i+") ["+Object.keys(updates).length+"]",updates);
      rdb.ref().update(updates);
      console.log("Já elvis!");

      updates = {};
      for(var idx in entregasSemMetas) {
        var elem = entregasSemMetas[idx];
        updates["/curriculo/avaliacao/2021S2/entregasA2SemMeta/"+elem.questaoID] = elem;
      }


      rdb.ref('/curriculo/avaliacao/2021S2/contadores/correcao').set(qtdeCorrecao);
      rdb.ref('/curriculo/avaliacao/2021S2/contadores/liberados').set(qtdeLiberado);
      rdb.ref('/curriculo/avaliacao/2021S2/contadores/entregas').set(qtdeEntregas);
      rdb.ref('/curriculo/avaliacao/2021S2/contadores/revisao').set(qtdeRevisao);

      console.log("Já elvis!");
    }
  }
}
</script>

<style scoped>
</style>
