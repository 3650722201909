<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" width="600">
      <v-card class="m-0 p-2">
        <div class="">
          <v-file-input show-size label="Planilha" @change="parseExcelFile1"></v-file-input>
        </div>
        <div class="mb2" v-if="abas.length > 0">
          <p>Abas:</p>
          <v-btn class="mr2" color="primary" x-small v-for="(item,index) in abas" :key="index" @click="parseAba(index)">{{item}}</v-btn>
          <v-btn color="warning" x-small @click="salvar">
            salvar
          </v-btn>
        </div>
        <div id="result1">
          <p v-if="linhas != null">
            linhas ({{abaSelecionada}}) [{{linhas.length}}]:
            <!-- <VueObjectView v-model="linhas" :key="refreshKey"/> -->
          </p>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import moment from 'moment';
import XLSX from 'xlsx';
import VueObjectView from 'vue-object-view';

export default {
  name: "",
  components: {},
  props: [],
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      dialog: false,
      abas: [],
      abaSelecionada: "",
      linhas: null,
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
  },
  methods:{

    build() {
      var self = this;
    },

    show() {
      this.dialog = true;
    },

    parseXLSX(abaIdx) {
      var arrayBuffer = this.reader.result;
      var options = { type: 'array' };
      this.workbook = XLSX.read(arrayBuffer, options);
      console.timeEnd();
      var sheetName = this.workbook.SheetNames
      this.abas = sheetName;
      console.log(sheetName);
      var sheet = this.workbook.Sheets[sheetName[abaIdx]]
      this.abaSelecionada = sheetName[0];
      console.log(sheet);
      this.linhas = XLSX.utils.sheet_to_json(sheet, {raw: false});
      console.log(this.linhas);
      this.refreshKey += 1;
      this.$forceUpdate();
    },

    parseExcelFile1(file) {
      var self = this;
      console.log("parseExcelFile1",file);
      if(file == undefined) return;
      console.time();
      this.reader = new FileReader();
      this.reader.onloadend = function(event) {
        self.parseXLSX(0);
      };
      this.reader.readAsArrayBuffer(file);
    },

    parseAba(abaIdx) {
      this.parseXLSX(abaIdx)
      this.$forceUpdate();
    },

    salvar() {
      var self = this;
      console.log("salvar");
      var texto = JSON.stringify(clone2(self.linhas));
      console.log("texto", texto);
      //localStorage.setItem('planilha', texto);
      this.$emit('atualiza',self.linhas);
      this.dialog = false;
    },

    salvar2() {
      var self = this;
      console.log("salvar");
      var pieces = [];
      var qtde = 0;
      var vet = [];
      for(var idx in self.linhas) {
        if(qtde >= 1000) {
          vet.push(self.linhas[idx]);
          pieces.push(vet);
          qtde = 0;
          vet = [];
        } else {
          vet.push(self.linhas[idx]);
          qtde++;
        }
      }
      console.log("pieces",pieces);
      for(var idx in pieces) {
        var texto = JSON.stringify(clone2(pieces[idx]));
        console.log("setItem: planilha-piece-"+idx);
        localStorage.setItem('planilha-piece-'+idx, texto);
      }
    }

  }
}
</script>

<style scoped>
</style>
